import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/loader';
import useCancellationToken from '../../hooks/useCancellationToken';
import { Job } from '../../models/job';
import Jobs from '../../services/jobs';
import JobHistoryTab from './JobHistoryTab';
import JobSettingsTab from './JobSettingsTab';
import JobTasksTab from './JobTasksTab';

type JobOverviewRouteParams = {
  id: string;
};

const JobOverview: FC = () => {
  const params = useParams<JobOverviewRouteParams>();

  const [job, setJob] = useState<Job | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [showRunModal, setShowRunModal] = useState<boolean>(false);
  const [runType, setRunType] = useState<string>('All');
  const [runDatabases, setRunDatabases] = useState<string[]>([]);

  const [testResult, setTestResult] = useState<string[]>([]);

  const history = useNavigate();

  const cancelToken = useCancellationToken();

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        const result = await Jobs.getById(params.id!, cancelToken);
        setJob(result);
      } catch (err: any) {
        setError(err);
      }
    })();
  }, [params.id, cancelToken]);

  useEffect(() => {
    if (runType === 'All') {
      return;
    }

    (async () => {
      const result = await Jobs.getDatabaseList(params.id!, cancelToken);
      setTestResult(result);
    })();
  }, [showRunModal, runType, params.id, cancelToken]);

  const handleRunJob = () => {
    setShowRunModal(true);
  };

  const handleRunCancel = () => {
    setShowRunModal(false);
  };

  const handleRunOk = async () => {
    setShowRunModal(false);

    let databaseList = runDatabases.join(',');
    if (runType === 'All') {
      databaseList = '';
    }

    var jobRunId = await Jobs.run(job!.jobId, databaseList);

    history(`/run/${jobRunId}`);
  };

  const getCheckBoxIncludeValue = (name: string): boolean => {
    return runDatabases.indexOf(name) > -1;
  };

  const handleCheckBoxIncludeChange = (name: string, checked: boolean): void => {
    if (checked) {
      setRunDatabases([...runDatabases, name]);
    } else {
      setRunDatabases([...runDatabases.filter((m) => m !== name)]);
    }
  };

  return (
    <>
      <Loader isLoaded={job != null} error={error}>
        <Box marginBottom={4}>
          <Heading>{job?.name}</Heading>
        </Box>
        <Box marginBottom={4}>
          <Button onClick={() => handleRunJob()}>Run Job</Button>
        </Box>
        <Tabs>
          <TabList>
            <Tab>History</Tab>
            <Tab>Tasks</Tab>
            <Tab>Configuration</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <JobHistoryTab job={job}></JobHistoryTab>
            </TabPanel>
            <TabPanel>
              <JobTasksTab job={job}></JobTasksTab>
            </TabPanel>
            <TabPanel>
              <JobSettingsTab job={job}></JobSettingsTab>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Loader>
      <Modal isOpen={showRunModal} onClose={handleRunCancel} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Run Job</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="runType" isRequired marginBottom={4}>
              <FormLabel>Run Type</FormLabel>
              <Select value={runType} onChange={(e) => setRunType(e.target.value)}>
                <option value="All">All items</option>
                <option value="Manual">Select items</option>
              </Select>
              <FormHelperText>
                Run the task for either all items generated by the first ask, or select which items to run the job for.
              </FormHelperText>
            </FormControl>
            {testResult.map((m) => (
              <FormControl marginBottom={2} key={m}>
                <Checkbox
                  isChecked={getCheckBoxIncludeValue(m)}
                  onChange={(e) => handleCheckBoxIncludeChange(m, e.currentTarget.checked)}
                >
                  {m}
                </Checkbox>
              </FormControl>
            ))}
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button onClick={() => handleRunOk()} colorScheme="blue">
                Run
              </Button>
              <Button onClick={() => handleRunCancel()}>Cancel</Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default JobOverview;
