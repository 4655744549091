import { Icon, IconProps, LayoutProps } from '@chakra-ui/react';

const LogoIcon: React.FC<IconProps & LayoutProps> = (props) => (
  <Icon viewBox="0 0 310 310" {...props}>
    <path
      fill="currentColor"
      d="M301 191c-3,31 -17,59 -38,80 -24,24 -58,39 -95,39l0 -11c34,0 65,-14 88,-36 18,-19 31,-44 35,-72l-12 0 17 -30 18 30 -13 0zm-65 43c-19,19 -46,31 -75,33l-9 0c-29,-2 -55,-14 -74,-33 -68,-68 -24,-187 74,-191l9 0c99,4 143,123 75,191zm-31 -41c5,2 9,8 9,14 0,1 0,2 -1,3 8,4 14,9 20,14 17,-18 27,-42 27,-69l0 0 -52 0c0,12 -1,25 -3,38zm5 25c-3,3 -8,5 -12,5l-1 0c-4,12 -9,24 -18,33 18,-5 35,-13 48,-25 -5,-5 -11,-10 -17,-13zm-21 3c-5,-3 -8,-8 -8,-14 0,0 0,-1 0,-1 -6,-2 -13,-3 -20,-3l0 54c14,-3 23,-21 28,-36zm-5 -23c3,-4 8,-7 13,-7 1,-12 2,-24 2,-36l-38 0 0 39c8,1 16,2 23,4zm14 1c-5,0 -8,4 -8,8 0,4 3,7 8,7 4,0 7,-3 7,-7 0,-4 -3,-8 -7,-8zm-82 -69c7,2 13,8 15,16l21 0 0 -36c-11,-1 -22,-3 -32,-6 -2,8 -3,17 -4,26zm14 25c-1,7 -7,13 -15,15 1,11 2,21 4,31 11,-4 22,-6 33,-7l0 -39 -22 0zm-24 15c-8,-1 -14,-7 -16,-15l-36 0 0 0c0,27 10,51 27,69 8,-8 19,-15 30,-20 -3,-11 -4,-22 -5,-34zm-16 -24c1,-9 8,-15 17,-17 1,-10 3,-20 5,-28 -10,-5 -20,-11 -29,-19 -16,17 -27,39 -29,64l36 0zm20 -8c-7,0 -12,5 -12,12 0,7 5,12 12,12 7,0 12,-5 12,-12 0,-7 -5,-12 -12,-12zm86 -57c5,0 9,3 13,6 5,-3 11,-7 15,-10 -13,-11 -28,-19 -45,-23 7,8 13,17 17,27l0 0zm16 14c0,1 0,2 0,3 0,5 -3,11 -8,14 2,10 3,22 4,34l52 0c-2,-25 -13,-47 -30,-64 -3,3 -11,9 -18,13zm-16 19c-5,0 -10,-3 -13,-7l0 0c-7,2 -14,3 -22,3l0 36 38 0c0,-11 -2,-22 -3,-32zm-16 -15c0,-1 0,-1 0,-1 0,-6 3,-11 7,-14l0 0c-5,-13 -14,-29 -26,-32l0 49c7,0 13,-1 19,-2zm16 -9c-4,0 -7,3 -7,8 0,4 3,7 7,7 4,0 8,-3 8,-7 0,-5 -4,-8 -8,-8zm-82 2c4,-13 11,-28 20,-38 -16,4 -32,12 -44,23 7,6 15,11 24,15l0 0zm38 9l0 -49c-15,4 -25,29 -30,43 10,4 20,6 30,6zm-31 108c4,16 15,44 31,48l0 -54c-11,1 -21,3 -31,6zm-34 22c13,12 29,20 47,25 -10,-12 -17,-28 -21,-43 -10,4 -19,10 -26,18zm-33 28c-25,-19 -42,-45 -49,-73 -9,-33 -6,-69 13,-101l9 5c-17,29 -21,63 -12,93 7,26 22,50 44,67l6 -11 18 31 -35 0 6 -11zm65 -248c29,-12 60,-14 88,-6 33,8 63,30 81,62l-9 5c-17,-29 -44,-49 -75,-57 -26,-7 -54,-6 -80,5l6 10 -34 0 17 -30 6 11z"
    />
  </Icon>
);

export default LogoIcon;
